export const artCons: any = {
    hcpMainMenu: "Main Navigational Menu HCP GCSO",
    patMainMenu: "Main Navigational Menu Consumer GCSO",
    hcpFooterMenu: "Footer Navigation Menu HCP GCSO",
    patFooterMenu: "Footer Navigation Menu Consumer GCSO",
    carousel: "",
    banner: "",
    hcpFooter: "Retina Global Footer HCP - Canada",
    patFooter: "Footer DTC HTML Canada",
    dtcExitPopup: "Retina Canada Exit Popup HTML",
    hcpExitPopup: "Retina Canada HCP Exit Popup HTML",
    hcpSwitcher: "HCP Switcher Modal Pop Up",
    hcpTopNav: "Top Navigational Menu HCP - Canada",
    patTopNav: "Top Navigation Menu Retina Canada HTML DTC",
    hcpFooterCallout: "HCP Homepage Footer Navigation Callout HTML - Canada",
    hcpSiteLogo: "Site logos HCP",
    patSiteLogo: "Site logos DTC",
    hcpSitemapLabel: "Retina HCP Sitemap - Full HTML",
    patSitemapLabel: "Retina DTC Sitemap - Full HTML",
    privacyPara: "Retina Canada Privacy Policy",
    bannerSitemapHCP: "Banner - HTML - Sitemap HCP - Canada",
    bannerSitemapDTC: "Banner - HTML - Sitemap DTC - Canada",
    bannerPrivacyHCP: "Banner - HTML - Privacy HCP - Canada",
    bannerPrivacyDTC: "Banner - HTML - Privacy DTC - Canada",
    hcpSMRedirectLangUrl: {
      "redirectUrl": {
        "en": "/hcp/sitemap",
        "fr": "/fr/hcp/sitemap"
      }
    },
    patSMRedirectLangUrl: {
      "redirectUrl": {
        "en": "/sitemap",
        "fr": "/fr/sitemap"
      }
    },
    hcpPPRedirectLangUrl: {
      "redirectUrl": {
        "en": "/hcp/privacy-policy",
        "fr": "/fr/hcp/privacy-policy"
      }
    },
    patPPRedirectLangUrl: {
      "redirectUrl": {
        "en": "/privacy-policy",
        "fr": "/fr/privacy-policy"
      }
    },
    backToTopGTMPP: "Back to top - privacy policy",
    backToTopGTMSM: "Back to top - site map",
    footerClassName: "common-footer"
  }
