import styled from 'styled-components'
import { device } from '@retina-packages/retina-theme-bootstrap/packages/css/device'

export const PrivacyPolicyHcpMain = styled.div`
.privacypolicyhcp{
  .privacy-policy-footer {
    .o-backtotop-btn {
      @media ${device.laptopMedium} {
        top: -100px !important;
      }
    }
    footer {
      @media ${device.laptopMedium} {
        padding: 0 10px !important;
      }
      padding: 0 5rem;
      @media ${device.desktopsignup} {
        max-width: 1208px;
        padding: 0 2rem;
      }
      .left-footer {
        .o-copyright {
          margin-top: 0;
          padding-top: 0;
          @media ${device.laptopMedium} {
            max-width: 100%;
          }
        }
      }
    }
  }
  .privacy-site{
    .internal-banner{
      @media (min-width: 769px) and (max-width: 991px) {
        height: 220px;
      }
      .internal-banner__contant{
        .o-container{
          h1{
            margin-top: -25px;
            @media ${device.ipadLandscapemin} {
              margin-top: -8px;
            }
            @media ${device.desktopsignup} {
              margin-top: -2px;
            }
          }
        }
      }

    }
    .clinical-trials-banner {
      &.mobile-banner{
        &:after{
          @media ${device.mobileS} and ${device.laptopMedium} {
            height: calc(100% + 30px);
          }
          @media ${device.laptopMedium}{
            height: calc(100% + 34px);
          }

        }
        .internal-banner {
          &.o-column-full {
            .internal-banner__image {
              .media--image {
                img {
                  @media (min-width: 769px) and (max-width: 991px) {
                    height: 220px;
                  }
                }
              }
            }
          }
          .internal-banner__image {
            .desktop-image {
              .media--image {
                img {
                  @media ${device.laptopPros}{
                    height: 150px;
                  }
                }
              }
            }
            .mobile-image{
              .media-image {
                img {
                  @media ${device.tabletMax}{
                    height: 150px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  }

  .privacypolicyhcppagewrap {
    .privacypolicycontent{
      @media ${device.ipadLandscapemin}{
        margin-top: -20px;
        padding: 0 5rem 80px;
      }

      div.intro-section{
        padding: 0 2rem;
        @media ${device.desktopsignup} {
          padding: 0 3rem;
        }
        h2.o-header--h2{
          color: var(--bs-body-color);
          @media ${device.mobileS} and ${device.mobileMedium}{
            margin-top: -5px;
          }
          @media ${device.tablet}{
            margin-top: 45px;
          }
          @media (min-width: 769px) and (max-width: 991px) {
            margin-top: 60px;
          }
          @media ${device.ipadLandscapemin} {
            margin-top: 60px;
          }
        }
      }
      .o-container{
        padding: 0px 2.7rem;
        @media ${device.ipadair} and ${device.ipadair3} {
          padding:right: 1.6rem;
        }
        @media ${device.ipadLandscapemin}{
          padding: 0 2rem;
        }
        @media ${device.desktopsignup}{
          padding: 0 2rem 0 3rem;
        }
      }
      .o-paragraph{
        line-height: 2.2rem;
        @media ${device.ipadLandscapemin}{
          font-size: 2.2rem;
          line-height: 3.2rem;
          font-family: "Gotham-Book", Arial, Helvetica, sans-serif;
        }
      &.o-para-fcb-bold{
        font-size: 2rem;
        margin: 0 0 20px;

        @media ${device.ipadLandscapemin}{
          font-size: 2.7rem;
          line-height: 3.8rem;
          font-family: "Gotham", Arial, Helvetica, sans-serif;
        }
        strong{
          font-weight: 700;
        }
      }
      }
    }
  }

  .o-container{
    max-width: 1208px !important;
  }
  .privacypolicyhcppagewrap{
    &:before{
      @media ${device.ipadLandscapemin} and ${device.desktopmenu} {
        top: -24px;
        height: calc(100% - 260px) !important;

      }
      @media ${device.desktopsignup}{
        height: calc(100% - 390px) !important;
      }
      @media ${device.desktopStart}{
        height: calc(100% - 260px) !important;
      }
    }
    .privacypolicycontent{
      .o-paragraph{
        a.purple-link{
            white-space: nowrap;
            &:hover {
              text-decoration: underline;
            }
        }
      }
      ul{
        li{
          &.o-paragraph{
            a.purple-link{
                color: #0d6efd;
                white-space: unset;
                &:hover {
                  color: #0a58ca;
                }
              }
          }
        }
      }
    }
  }
  footer{
    margin: 0px auto;
    .o-copyright {
      margin: 0 auto 20px;
      line-height: 24px;
    }
    .left-footer {
      .navbar-nav {
        margin-bottom: 0;
        .footer-list {
          margin: 10px 0;
        }
      }
    }
  }
}



  .privacypolicydtc {
    .privacy-site {
      .internal-banner {
        height: 100%;
        &:after {
          @media ${device.laptopMedium} {
            height: 32px;
            top: calc(100% - 32px);
          }
        }
        .internal-banner__contant {
          @media ${device.ipadLandscapemin} and ${device.miniTopDeskMax} {
            top: calc(50% - 23px);
          }
          @media ${device.laptopMedium} {
            top: 28px;
            transform: translateY(0px);
          }
          .o-container {
            @media ${device.ipadLandscapemin} and ${device.miniTopDeskMax} {
              padding: 0 2rem;
            }
          }
          h1 {
            &.o-header--h1 {
              @media ${device.mobileS} and ${device.mobilemax} {
                margin-top: 0px !important;
              }
              @media ${device.ipadLandscapemin} and ${device.miniTopDeskMax} {
                padding-left: 0 !important;
                margin-top: 8px;
              }
              @media ${device.extraLargeDesktop} {
                margin-top: 17px;
              }
            }
          }
        }
        .internal-banner__image {
          div.media--image {
            img {
              @media ${device.ipadLandscapemin} {
                height: 100%;
              }
              @media ${device.laptopPros} {
                height: 150px;
              }
            }
          }
        }
      }
      .privacypolicycontent {
        .o-container {
          max-width: 718px !important;
          @media ${device.ipadLandscapemin} {
            max-width: 1208px !important;
          }
        }
      }
    }
    .o-paragraph {
      &.o-para-fcb-bold {
        font-family: "Gotham-Book", Arial, Helvetica, sans-serif;
        margin-bottom: 20px;
        color: rgb(0, 0, 0);
        margin-top: 0px;
      }
    }
    .privacy-policy-footer {
      .o-backtotop-btn {
        @media ${device.laptopMedium} {
          top: -100px !important;
        }
      }
    }
  }
`;
